import React from "react";

const Home = () => {
  return (
    <div className="sports-event-home">
      <h1> COLLEGE SPORTS EVENT</h1>
    </div>
  );
};

export default Home;
