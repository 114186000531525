export const ALL_EVENTS_DATA = [
  {
    start_time: "2024-12-17 16:00:00",
    event_category: "Swimming",
    event_name: "Butterfly 100M",
    id: "1",
    type: 'SPORTS',
    end_time: "2024-12-17 17:00:00",
  },
  {
    start_time: "2024-12-18 14:00:00",
    event_category: "Cricket",
    event_name: "T 20",
    id: "2",
    type: 'SPORTS',
    end_time: "2024-12-18 15:00:00",
  },
  {
    start_time: "2024-12-19 14:00:00",
    event_category: "Table Tennis",
    event_name: "2 Player",
    id: "3",
    type: 'SPORTS',
    end_time: "2024-12-19 15:00:00",
  },
  {
    start_time: "2024-12-20 14:00:00",
    event_category: "Indoor Games",
    event_name: "Carrom Board",
    id: "4",
    type: 'SPORTS',
    end_time: "2024-12-20 17:00:00",
  },
  {
    start_time: "2024-12-20 12:30:00",
    event_category: "Badminton",
    event_name: "Single/Double",
    id: "5",
    type: 'SPORTS',
    end_time: "2024-12-20 13:00:00",
  },
  {
    start_time: "2024-12-17 14:00:00",
    event_category: "Volley Ball",
    event_name: "Double",
    id:" 6",
    type: 'SPORTS',
    end_time: "2024-12-17 14:30:00",
  },
  {
    start_time: "2024-12-17 14:00:00",
    event_category: "Swimming",
    event_name: "Butterfly 100M",
    id:" 7",
    type: 'SPORTS',
    end_time: "2024-12-17 14:30:00",
  },
  {
    start_time: "2024-12-17 13:00:00",
    event_category: "Football",
    event_name: "Football",
    id:" 8",
    type: 'SPORTS',
    end_time: "2024-12-17 13:30:00",
  },
  {
    start_time: "2024-12-17 12:30:00",
    event_category: "Kho Kho",
    event_name: "Kho Kho",
    id: "9",
    type: 'SPORTS',
    end_time: "2024-12-17 13:00:00",
  },
  {
    start_time: "2024-12-17 13:30:00",
    event_category: "Table Tennis",
    event_name: "Doubles",
    id: "10",
    type: 'SPORTS',
    end_time: "2024-12-17 13:00:00",
  },
  {
    start_time: "2024-12-19 14:00:00",
    event_category: "Football",
    event_name: "Butterfly 100M",
    id: "11",
    type: 'SPORTS',
    end_time: "2024-12-19 14:30:00",
  },
  {
    start_time: "2024-12-20 16:00:00",
    event_category: "Kho Kho",
    event_name: "Butterfly 100M",
    id: "12",
    type: 'SPORTS',
    end_time: "2024-12-20 16:30:00",
  },
  {
    start_time: "2024-12-17 14:00:00",
    event_category: "Table Tennis",
    event_name: "Butterfly 100M",
    id: "13",
    type: 'SPORTS',
    end_time: "2024-12-17 15:00:00",
  },
  {
    start_time: "2024-12-17 14:00:00",
    event_category: "Table Tennis",
    event_name: "Butterfly 100M",
    id: "14",
    type: 'SPORTS',
    end_time: "2024-12-17 15:00:00",
  },
  {
    start_time: "2024-12-19 10:00:00",
    event_category: "Football",
    event_name: "Butterfly 100M",
    id: "15",
    type: 'SPORTS',
    end_time: "2024-12-19 11:00:00",
  },
];
